import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Form } from 'react-bootstrap';

const PurchaseOrderPage = () => {
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentOrder, setCurrentOrder] = useState({});
  const [orderDetails, setOrderDetails] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchPurchaseOrders = async (page = 1, limit = 10) => {
    const token = localStorage.getItem('jwtToken');
    try {
      const response = await axios.get('https://io.pbw.net/api/purchaseOrders', {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json' 
        },
        params: { page, limit }
      });

      const { data, pageInfo } = response.data;
      setPurchaseOrders(data);
      setCurrentPage(pageInfo.currentPage);
      setTotalPages(pageInfo.totalPages);
    } catch (error) {
      console.error('Error fetching purchase orders:', error.toString());
    }
  };

  useEffect(() => {
    fetchPurchaseOrders(); 
  }, []);

  const handleShowModal = (order = {}) => {
    setCurrentOrder(order);
    setOrderDetails(order.details || []);
    setIsUpdate(Object.keys(order).length !== 0);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentOrder({});
    setOrderDetails([]);
  };

  const handleAddItem = () => {
    setOrderDetails([...orderDetails, { productID: '', quantity: 1, cost: 0, totalCost: 0, expectedDeliveryDate: '' }]);
  };

  const handleItemChange = (index, field, value) => {
    const updatedDetails = [...orderDetails];
    updatedDetails[index][field] = value;
    if (field === 'quantity' || field === 'cost') {
      updatedDetails[index].totalCost = updatedDetails[index].quantity * updatedDetails[index].cost;
    }
    setOrderDetails(updatedDetails);
  };

  const handleRemoveItem = (index) => {
    const updatedDetails = [...orderDetails];
    updatedDetails.splice(index, 1);
    setOrderDetails(updatedDetails);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem('jwtToken');
    const formData = new FormData(event.target);
    const orderData = Object.fromEntries(formData.entries());

    try {
      const headers = {
        'Authorization': `Bearer ${token}`,
      };

      if (isUpdate) {
        const orderId = currentOrder.purchaseOrderID;
        await axios.put(`https://io.pbw.net/api/purchaseOrders/${orderId}`, { orderData, orderDetails }, { headers });
      } else {
        await axios.post('https://io.pbw.net/api/purchaseOrders/', { orderData, orderDetails }, { headers });
      }

      fetchPurchaseOrders(currentPage); 
      handleCloseModal();

    } catch (error) {
      console.error('Error saving the purchase order:', error.toString());
    }
  };

  const handleDelete = async (orderId) => {
    const token = localStorage.getItem('jwtToken');
    try {
      await axios.delete(`https://io.pbw.net/api/purchaseOrders/${orderId}`, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json'
        }
      });
      fetchPurchaseOrders(currentPage);
    } catch (error) {
      console.error('Error deleting the purchase order:', error.toString());
    }
  };

  return (
    <div>
      <h1>Purchase Orders</h1>
      <Button onClick={() => handleShowModal()}>Add Purchase Order</Button>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Order Number</th>
            <th>Partner Name</th>
            <th>Order Date</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {purchaseOrders && purchaseOrders.length > 0 ? (
            purchaseOrders.map(order => (
              <tr key={order.purchaseOrderID}>
                <td>{order.orderNumber}</td>
                <td>{order.partnerName}</td>
                <td>{order.orderDate}</td>
                <td>{order.status}</td>
                <td>
                  <Button variant="info" size="sm" onClick={() => handleShowModal(order)}>Edit</Button>{' '}
                  <Button variant="danger" size="sm" onClick={() => handleDelete(order.purchaseOrderID)}>Delete</Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No purchase orders found.</td>
            </tr>
          )}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{isUpdate ? 'Update Purchase Order' : 'Add Purchase Order'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Order Number</Form.Label>
              <Form.Control name="orderNumber" defaultValue={currentOrder.orderNumber || ''} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Partner ID</Form.Label>
              <Form.Control name="partnerID" defaultValue={currentOrder.partnerID || ''} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Order Date</Form.Label>
              <Form.Control name="orderDate" type="date" defaultValue={currentOrder.orderDate || ''} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Expected Delivery Date</Form.Label>
              <Form.Control name="expectedDeliveryDate" type="date" defaultValue={currentOrder.expectedDeliveryDate || ''} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Select name="status" defaultValue={currentOrder.status || 'Pending'}>
                <option value="Pending">Pending</option>
                <option value="Received">Received</option>
                <option value="Cancelled">Cancelled</option>
              </Form.Select>
            </Form.Group>

            <h5>Order Details</h5>
            <Button variant="secondary" size="sm" onClick={handleAddItem}>Add Item</Button>
            <Table striped bordered hover className="mt-3">
              <thead>
                <tr>
                  <th>Product ID</th>
                  <th>Quantity</th>
                  <th>Cost</th>
                  <th>Total Cost</th>
                  <th>Expected Delivery Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {orderDetails.map((detail, index) => (
                  <tr key={index}>
                    <td>
                      <Form.Control 
                        type="text" 
                        value={detail.productID} 
                        onChange={(e) => handleItemChange(index, 'productID', e.target.value)} 
                        required
                      />
                    </td>
                    <td>
                      <Form.Control 
                        type="number" 
                        value={detail.quantity} 
                        onChange={(e) => handleItemChange(index, 'quantity', e.target.value)} 
                        required
                      />
                    </td>
                    <td>
                      <Form.Control 
                        type="number" 
                        step="0.01" 
                        value={detail.cost} 
                        onChange={(e) => handleItemChange(index, 'cost', e.target.value)} 
                        required
                      />
                    </td>
                    <td>{detail.totalCost.toFixed(2)}</td>
                    <td>
                      <Form.Control 
                        type="date" 
                        value={detail.expectedDeliveryDate || ''} 
                        onChange={(e) => handleItemChange(index, 'expectedDeliveryDate', e.target.value)} 
                      />
                    </td>
                    <td>
                      <Button variant="danger" size="sm" onClick={() => handleRemoveItem(index)}>Remove</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <Button variant="primary" type="submit">
              Save Purchase Order
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PurchaseOrderPage;
